import Api from './api';
import { logErrorsAndExceptions } from './logger.js';
import store from './store';
import { initializeAnalytics, identifyUser } from './react-land/tracking';

class User {
  async login(email, password) {
    try {
      const res = await Api.post('/account/login', { email, password });

      return res.status === 200 && (await this.loadStatus());
    } catch (err) {
      logErrorsAndExceptions('User.login', err);

      return false;
    }
  }

  async adminLogin(audience, token) {
    try {
      const res = await Api.post('/account/adminLogin', { audience, token });

      return res.status === 200;
    } catch (err) {
      logErrorsAndExceptions('User.adminLogin', err);

      return false;
    }
  }

  async loadStatus() {
    try {
      const res = await Api.get('/account/status');

      if (res.status === 200) {
        store.commit('updateUserCompany', res.data.company);
        store.commit('updateUserRole', res.data.role);
        store.commit('updateFeatureFlags', res.data.featureFlags);
        store.commit('updateIsAdmin', res.data.isAdmin);
        store.commit('updateIsSelfServe', res.data.isSelfServe);
        store.commit('updateIsUmsEnabled', res.data.isUmsEnabled);
        store.commit('updateFeaturePackage', res.data.featurePackage);

        if (res.data.isSelfServe) {
          initializeAnalytics();
          identifyUser(res.data.company);
        }
      }

      return res.status === 200;
    } catch (err) {
      logErrorsAndExceptions('User.loadStatus', err);

      return false;
    }
  }

  async loadCompanies() {
    try {
      const res = await Api.get('/account/companies');

      if (res.status === 200) {
        store.commit('updateUserCompanies', res.data.companies);
      }

      return res.status === 200;
    } catch (err) {
      logErrorsAndExceptions('User.loadCompanies', err);

      return false;
    }
  }

  async loadChargeBeeSubscription() {
    if (!store.getters.isSelfServe) {
      store.commit('updateChargeBeeSubscription', null);

      return true;
    }

    try {
      const response = await Api.get('SelfServe/ChargeBee/Subscription');
      if (response.status === 200) {
        store.commit('updateChargeBeeSubscription', response.data);
      }
    } catch (error) {
      logErrorsAndExceptions('User.loadChargeBeeSubscription', error);

      return false;
    }

    return true;
  }

  async changeDashboard(company) {
    try {
      const res = await Api.post('/account/changedashboard', { company });

      if (res.status === 200) {
        store.commit('updateUserCompany', res.data.company);
        store.commit('updateUserRole', res.data.role);
        store.commit('updateFeatureFlags', res.data.featureFlags);
        store.commit('updateIsSelfServe', res.data.isSelfServe);
        store.commit('updateIsUmsEnabled', res.data.isUmsEnabled);
      }

      return res.status === 200;
    } catch (err) {
      logErrorsAndExceptions('User.changeDashboard', err);

      return false;
    }
  }

  async activate(email, password, token) {
    try {
      const res = await Api.post('/selfserve/account/activate', {
        email,
        password,
        token
      });

      return res.status === 200;
    } catch (err) {
      logErrorsAndExceptions('User.activate', err);

      return false;
    }
  }

  async forgotPassword(email) {
    try {
      const res = await Api.post('/account/resetpasswordemail', { email });

      return res.status === 200;
    } catch (err) {
      logErrorsAndExceptions('User.forgotPassword', err);

      return false;
    }
  }

  async resetPassword(id, token, newPassword) {
    try {
      const res = await Api.post('/account/resetpassword', {
        id,
        token,
        newPassword
      });

      return res.status === 200;
    } catch (err) {
      logErrorsAndExceptions('User.resetPassword', err);

      return false;
    }
  }

  async logout() {
    try {
      await Api.post('/account/logout');
    } catch (err) {
      logErrorsAndExceptions('User.logout', err);
    }
  }
}

export default new User();
